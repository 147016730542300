// $contact-address-email: $persianRed;
$contact-address-font-size: 0.875rem;
$contact-address-label: #999;
// $contact-headline: $black;
// $contact-headline-font-size: 0.875rem;
// $contact-icons-bg: $persianRed;
// $contact-icons-color: $white;
$contact-navigation-font-size: 0.875rem;
// $contact-navigation-link: $nobel;

.contact {
    margin-top: 50pt;
    border-top: 15px solid #c53838;
    padding: 30px 5%;

    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
        text-align: center;

        // .headline {
        //     color: $contact-headline;
        //     font-size: $contact-headline-font-size;
        // }

        .contact-address {
            justify-self: center;

            .address {
                font-size: $contact-address-font-size;
                font-style: normal;

                > div {
                    margin: 20px 0;
                }

                .label {
                    color: $contact-address-label;
                    display: inline;
                    margin: 0 5px 0 0;
                    padding: 0;
                }

                .phone-number,
                .email {
                    font-weight: 600;
                    text-align: left;
                    color: #c53939;
                }
            }
        }

        .link-collection {

            .navigation {
                font-size: $contact-navigation-font-size;
                margin: 0;

                .item-container {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    .item {
                        padding: 10px 0;

                        .link {
                            text-align: left;
                            color: #999;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (min-width: 768px) {
    .contact {

        .container {
            grid-template-columns: repeat(3, 1fr);

            .contact-address,
            .link-collection,
            .social-media {
                justify-self: center;
                text-align: left;
            }

            .social-media {
                justify-self: start;
            }

            .contact-address {
                .address {
                    grid-row-gap: 10px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200) {
    .contact {
        .container {
            grid-column-gap: 30px;
        }
    }
}

$social-media-icons-background: #c53939;
$social-media-icons-color: #fff;
$social-media-icons-font-size: 1.5rem;


.social-media {
    grid-row: 3;

    .icons-container {
        padding: 10px 0 0 0;

        .icon {
            background: $social-media-icons-background;
            border-radius: 50%;
            color: $social-media-icons-color;
            font-size: $social-media-icons-font-size;
            margin: 0 5px;
            padding: 20px 8px 1px;

            svg {
                height: 35px;
                width: 35px;
            }

            &:first-child {
                margin: 0 5px 0 0;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .social-media {
        grid-row: 1;
    }
}

// @font-face {
//   font-family: 'icomoon';
//   src: url('../../Fonts/icomoon/icomoon.eot?pn39mt');
//   src: url('../../Fonts/icomoon/icomoon.eot?pn39mt#iefix') format('embedded-opentype'),
//   url('../../Fonts/icomoon/icomoon.ttf?pn39mt') format('truetype'),
//   url('../../Fonts/icomoon/icomoon.woff?pn39mt') format('woff'),
//   url('../../Fonts/icomoon/icomoon.svg?pn39mt#icomoon') format('svg');
//   font-style: normal;
//   font-weight: normal;
// }


.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


$copyright-area-border: rgba(0, 0, 0, 0.05);
$copyright-navigation: #999;
$copyright-mobile-font-size: 0.75rem;
$copyright-desktop-font-size: 0.8125rem;
$copyright-navigation-link: #999;

.copyright-collection {
    border-top: 1px solid $copyright-area-border;
    text-align: center;
    padding: 30px 0;

    .item {
        display: inline;
        font-size: $copyright-mobile-font-size;
        padding: 0 10px;

        a {
            color: $copyright-navigation-link;
        }
    }
}

@media screen and (min-width: 768px) {

    .copyright-collection {
        padding: 10px 0;

        .item {
            font-size: $copyright-desktop-font-size;
        }
    }
}
